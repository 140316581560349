// vue
import { useStore } from 'vuex';

// axios
import axios from 'axios';

// hooks
import { useToast } from 'vue-toastification';

export const useAuthHeader = () => {
    // hooks
    const store = useStore();
    const toast = useToast();

    // methods
    const handleError = (err: any) => {
        if (err.response.data.message) {
            toast.error(err.response.data.message);
        } else if (err.response.data.errors) {
            let errors = '';
            err.response.data.errors.forEach((error: string, i: number) => {
                errors += '    ' + error;
                if (i !== errors.length - 1) {
                    errors += '\n';
                }
            });
            toast.error('Validation error:\n' + errors);
        } else {
            toast.error(JSON.stringify(err));
        }
        throw err;
    };

    const get = (url: string): any => {
        const axiosInstance = axios
            .get(url, {
                cancelToken: store.state.cancelToken.token,
                headers: {
                    Authorization: 'Bearer ' + store.state.authToken.token,
                },
            })
            .catch((err: any) => {
                handleError(err);
            });
        return axiosInstance;
    };

    const post = (url: string, data: any): any => {
        const axiosInstance = axios
            .post(url, data, {
                cancelToken: store.state.cancelToken.token,
                headers: {
                    Authorization: 'Bearer ' + store.state.authToken.token,
                },
            })
            .catch((err: any) => {
                handleError(err);
            });
        return axiosInstance;
    };

    const postMultipart = (url: string, data: any): any => {
        const axiosInstance = axios
            .post(url, data, {
                cancelToken: store.state.cancelToken.token,
                headers: {
                    Authorization: 'Bearer ' + store.state.authToken.token,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .catch((err: any) => {
                handleError(err);
            });
        return axiosInstance;
    };

    const put = (url: string, data: any): any => {
        const axiosInstance = axios
            .put(url, data, {
                cancelToken: store.state.cancelToken.token,
                headers: {
                    Authorization: 'Bearer ' + store.state.authToken.token,
                },
            })
            .catch((err: any) => {
                handleError(err);
            });
        return axiosInstance;
    };

    const deleteId = (url: string): any => {
        const axiosInstance = axios
            .delete(url, {
                cancelToken: store.state.cancelToken.token,
                headers: {
                    Authorization: 'Bearer ' + store.state.authToken.token,
                },
            })
            .catch((err: any) => {
                handleError(err);
            });
        return axiosInstance;
    };

    const deleteObject = (url: string, data: any): any => {
        const axiosInstance = axios
            .delete(url, {
                cancelToken: store.state.cancelToken.token,
                data,
                headers: {
                    Authorization: 'Bearer ' + store.state.authToken.token,
                },
            })
            .catch((err: any) => {
                handleError(err);
            });
        return axiosInstance;
    };

    const download = (url: string): any => {
        const axiosInstance = axios
            .get(url, {
                cancelToken: store.state.cancelToken.token,
                responseType: 'blob',
                headers: {
                    Authorization: 'Bearer ' + store.state.authToken.token,
                },
            })
            .catch((err: any) => {
                handleError(err);
            });
        return axiosInstance;
    };

    return {
        get,
        post,
        postMultipart,
        put,
        deleteObject,
        deleteId,
        download,
    };
};
