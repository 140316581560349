
export default {
    props: ['row', 'field', 'exported'],
    // eslint-disable-next-line
    setup(props: any) {
        return {
            props,
        };
    },
};
