export class TileserverConfig {
    public id?: number;
    public courseSchemaId!: number;
    public courseName!: string;
    public key!: string;
    public mbtiles!: string;
    public displayName!: string;
    public description!: string;
    public mosaicOrder!: number;
    public isPinzoneMosaic!: boolean;
    public isPlayerappMosaic!: boolean;
}
