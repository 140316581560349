// vue
import { ref } from 'vue';
import { useStore } from 'vuex';

// axios
import { AxiosPromise } from 'axios';

// hooks
import { useAuthHeader } from './auth-header';

// models
import { Course } from '@/api/model/course.model';

export const useCourseSchemaService = () => {
    // hooks
    const store = useStore();
    const authHeader = useAuthHeader();
    const ctsurl = store.state.ctsUrl;

    // properties
    const url = store.state.apiUrl;
    const courseSchemas = ref<Course[]>([]);

    // methods
    const getCourseSchema = async (id: number): Promise<AxiosPromise> => {
        const res = await authHeader.get(
            `${url}/environment/${id}/course-schema`,
        );
        courseSchemas.value = res.data;
        return res;
    };

    const clearCache = async (courseId: any): Promise<AxiosPromise> => {
        const res = await authHeader.post(
            `${ctsurl}/tileconfig/clearcache/${courseId}`,
            '',
        );
        return res;
    };
    const updateCache = async (courseId: any): Promise<AxiosPromise> => {
        const res = await authHeader.post(
            `${ctsurl}/tileconfig/updatecache/${courseId}`,
            '',
        );
        return res;
    };

    const clearAllCache = async (): Promise<AxiosPromise> => {
        const res = await authHeader.post(`${ctsurl}/tileconfig/clearall`, '');
        return res;
    };

    const addCourseSchema = async (
        environmentId: number,
        courseSchema: any,
    ): Promise<AxiosPromise> => {
        const res = await authHeader.post(
            `${url}/environment/${environmentId}/course-schema`,
            courseSchema,
        );
        await getCourseSchema(environmentId);
        return res;
    };

    const deleteCourseSchema = async (
        id: number,
        environmentId: number,
    ): Promise<AxiosPromise> => {
        const res = await authHeader.deleteId(
            `${url}/environment/${environmentId}/course-schema/${id}`,
        );
        await getCourseSchema(environmentId);
        return res;
    };

    const updateCourseSchema = async (
        environmentId: number,
        courseSchema: any,
    ): Promise<AxiosPromise> => {
        const res = await authHeader.put(
            `${url}/environment/${environmentId}/course-schema`,
            courseSchema,
        );
        await getCourseSchema(environmentId);
        return res;
    };

    const deleteGreenData = async (
        courseSchemaId: number,
        environmentId: number,
    ): Promise<AxiosPromise> => {
        const res = await authHeader.deleteId(
            `${url}/environment/${environmentId}/public/green_data/${courseSchemaId}`,
        );
        return res;
    };

    return {
        courseSchemas,
        getCourseSchema,
        updateCache,
        clearCache,
        clearAllCache,
        addCourseSchema,
        deleteCourseSchema,
        updateCourseSchema,
        deleteGreenData,
    };
};
