
// vue
import { defineComponent, ref, computed } from 'vue';

// hooks
import { useToast } from 'vue-toastification';

// services
import { useStore } from 'vuex';
import { useDataMigrationService } from '@/api/service/data-migration-service';

// models
import { DataMigration } from '@/api/model/data-migration.model';

export default defineComponent({
    props: [
        'environments',
        'selectedCourseIds',
        'courseSchemas',
        'closeModal',
        'environment',
        'pushStartedCallback',
        'pushFinishedCallback',
    ],
    setup(props: any) {
        // hooks
        const toast = useToast();

        // services
        const store = useStore();
        const { createDataMigration } = useDataMigrationService();

        // properties
        const selectedEnvironmentId = ref(0);
        const dataMigration = new DataMigration();
        const isPushLoading = ref(false);

        // computed
        const targetEnvironment = computed(() => {
            return props.environments.find(
                (x: any) => x.id === selectedEnvironmentId.value,
            );
        });

        const isAuthorized = computed(() => {
            return (
                !targetEnvironment.value.isProtected ||
                store.state.accountInfo.permissions.indexOf(
                    'canAuthorizeDataMigration',
                ) !== -1
            );
        });

        const filteredEnvironments = computed(() => {
            return props.environments.filter(
                (x: any) => x.id !== props.environment.id,
            );
        });

        // methods
        const pushMigration = async () => {
            dataMigration.sourceEnvironmentId = props.environment.id;
            dataMigration.targetEnvironmentId = targetEnvironment.value.id;
            dataMigration.courseIdArray = props.courseSchemas
                .filter(
                    (x: any) => props.selectedCourseIds.indexOf(x.id) !== -1,
                )
                .map((x: any) => x.id);
            dataMigration.requestedByAccountId =
                store.state.accountInfo.accountId;

            try {
                await createDataMigration(dataMigration);
                if (isAuthorized.value) {
                    toast.success('Successfully migrated data');
                } else {
                    toast.success('Successfully requested data migration');
                }
            } catch (e) {
                window.console.log(e);
            }
            selectedEnvironmentId.value = 0;
        };

        const triggerPush = async () => {
            isPushLoading.value = true;
            if (props.pushStartedCallback) {
                props.pushStartedCallback();
            }
            try {
                await pushMigration();
            } catch (e) {
                window.console.log(e);
            }
            if (props.pushFinishedCallback) {
                props.pushFinishedCallback();
            }
            isPushLoading.value = false;
        };

        return {
            store,
            selectedEnvironmentId,
            targetEnvironment,
            pushMigration,
            triggerPush,
            isPushLoading,
            isAuthorized,
            filteredEnvironments,
        };
    },
});
