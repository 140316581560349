import { CrBoolean } from '@/components/grid-plugins';

export const tileserverConfigConfig: any[] = [
    {
        title: 'Id',
        field: 'id',
        fitWidth: true,
    },
    {
        title: 'Course Schema Id',
        field: 'courseSchemaId',
    },
    {
        title: 'Course Name',
        field: 'courseName',
    },
    {
        title: 'Key',
        field: 'key',
    },
    {
        title: 'MB Tiles',
        field: 'mbtiles',
    },
    {
        title: 'Display Name',
        field: 'displayName',
    },
    {
        title: 'Description',
        field: 'description',
    },
    {
        title: 'Mosaic Order',
        field: 'mosaicOrder',
    },
    {
        title: 'Is Pinzone Mosaic',
        field: 'isPinzoneMosaic',
        fitWidth: true,
        cellRenderer: {
            component: CrBoolean,
        },
    },
    {
        title: 'Is Playerapp Mosaic',
        field: 'isPlayerappMosaic',
        fitWidth: true,
        cellRenderer: {
            component: CrBoolean,
        },
    },
];
