import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-96a408c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown" }
const _hoisted_2 = { class: "arrow" }
const _hoisted_3 = { class: "ml-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["dropdown-title", { 'is-open': _ctx.isOpen }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("i", {
          class: _normalizeClass(["far fa-chevron-right", { 'rotate-arrow': _ctx.isOpen }])
        }, null, 2)
      ]),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.props.title), 1)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["dropdown-content", { hidden: !_ctx.isOpen }])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ]))
}