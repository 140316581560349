// vue
import { ref } from 'vue';
import { useStore } from 'vuex';

// axios
import { AxiosPromise } from 'axios';

// hooks
import { useAuthHeader } from './auth-header';

// models
import { Environment } from '../model/environment.model';

export const useEnvironmentService = () => {
    // hooks
    const store = useStore();
    const authHeader = useAuthHeader();

    // properties
    const url = store.state.apiUrl;
    const environments = ref<Environment[]>([]);

    // methods
    const getEnvironmentAll = async (): Promise<AxiosPromise> => {
        const res = await authHeader.get(`${url}/environment`);
        environments.value = res.data;
        return res;
    };

    const getEnvironment = async (
        environmentId: number,
    ): Promise<Environment> => {
        const res = await authHeader.get(`${url}/environment/${environmentId}`);
        return res.data;
    };

    const createEnvironment = async (
        environment: Environment,
    ): Promise<AxiosPromise> => {
        const res = await authHeader.post(`${url}/environment`, environment);
        getEnvironmentAll();
        return res;
    };

    const updateEnvironment = async (
        environment: Environment,
    ): Promise<AxiosPromise> => {
        const res = await authHeader.put(`${url}/environment`, environment);
        getEnvironmentAll();
        return res;
    };

    const deleteEnvironment = async (
        environmentId: number,
    ): Promise<AxiosPromise> => {
        const res = await authHeader.deleteId(
            `${url}/environment/${environmentId}`,
        );
        getEnvironmentAll();
        return res;
    };

    const getConfigFile = async (): Promise<AxiosPromise> => {
        const res = await authHeader.get(`${url}/tileconfig`);
        return res;
    };

    const updateConfigData = async (): Promise<AxiosPromise> => {
        const res = await authHeader.post(`${url}/tileconfig`, '');
        return res;
    };

    const saveConfigFile = async (data: any): Promise<AxiosPromise> => {
        const res = await authHeader.post(`${url}/tileconfig/save`, data);
        return res;
    };

    return {
        environments,
        getEnvironment,
        getEnvironmentAll,
        createEnvironment,
        updateEnvironment,
        deleteEnvironment,
        getConfigFile,
        updateConfigData,
        saveConfigFile,
    };
};
