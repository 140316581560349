import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_clere_title = _resolveComponent("clere-title")!
  const _component_vm_button = _resolveComponent("vm-button")!
  const _component_vm_grid = _resolveComponent("vm-grid")!
  const _component_vm_card = _resolveComponent("vm-card")!
  const _component_vm_container = _resolveComponent("vm-container")!
  const _component_vm_card_header = _resolveComponent("vm-card-header")!
  const _component_account_editor = _resolveComponent("account-editor")!
  const _component_vm_card_content = _resolveComponent("vm-card-content")!
  const _component_vm_modal = _resolveComponent("vm-modal")!
  const _component_confirm = _resolveComponent("confirm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_vm_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_clere_title, null, {
          default: _withCtx(() => [
            _createTextVNode(" Accounts ")
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_vm_button, {
            primaryColor: "var(--triadic2-600)",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addAccount()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" ADD ACCOUNT ")
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_vm_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_vm_grid, {
              config: _ctx.gridConfig,
              rowData: _ctx.accountSummaries,
              isLoading: _ctx.isLoading
            }, null, 8, ["config", "rowData", "isLoading"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_vm_modal, { ref: "accountEditorModal" }, {
      default: _withCtx(() => [
        _createVNode(_component_vm_card_header, null, {
          default: _withCtx(() => [
            _createTextVNode("Account editor")
          ]),
          _: 1
        }),
        _createVNode(_component_vm_card_content, null, {
          default: _withCtx(() => [
            _createVNode(_component_account_editor, {
              account: _ctx.account,
              saveCallback: _ctx.saveAccount,
              closeCallback: _ctx.hideModal
            }, null, 8, ["account", "saveCallback", "closeCallback"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 512),
    _createVNode(_component_vm_modal, { ref: "confirmDeleteModal" }, {
      default: _withCtx(() => [
        _createVNode(_component_vm_card_header, null, {
          default: _withCtx(() => [
            _createTextVNode("Delete account")
          ]),
          _: 1
        }),
        _createVNode(_component_vm_card_content, null, {
          default: _withCtx(() => [
            _createVNode(_component_confirm, {
              closeModal: _ctx.hideDeleteModal,
              confirm: _ctx.deleteSelectedAccount,
              primaryColor: "var(--red-100)"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Are you sure you want to delete account " + _toDisplayString(_ctx.account.firstName) + " " + _toDisplayString(_ctx.account.lastName) + "? ", 1)
              ]),
              _: 1
            }, 8, ["closeModal", "confirm"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 512)
  ], 64))
}