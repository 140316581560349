export class Role {
    public id = 0;
    public name = '';
    public permissions: string[] = [];
}

/* export class RoleSummary {
    public id: number = 0;
    public name: string = '';
    public organizationName: string = '';
    public permissions: string[] = [];
} */
