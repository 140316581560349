
// vue
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';

// assets
import clerenetLogo from '@/assets/clerenet-logo.png';
import loginLeft from '@/assets/svg/login-left.svg';
import loginRight from '@/assets/svg/login-right.svg';

// services
import { useAuthService } from '@/api/service/auth.service';

// models
import { LoginCredentials } from '@/api/model/login.model';

export default defineComponent({
    setup() {
        // hooks
        const router = useRouter();

        // services
        const { logIn } = useAuthService();

        // properties
        const loginCredentials = ref(new LoginCredentials());

        // methods
        const login = async () => {
            await logIn(loginCredentials.value);
            router.push({ path: '/data-manager' });
        };

        return {
            clerenetLogo,
            loginLeft,
            loginRight,
            login,
            loginCredentials,
        };
    },
});
