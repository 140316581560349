export class Environment {
    public id?: number;
    public name!: string;
    public tag!: string;
    public frontEndUrl!: string;
    public dbHost!: string;
    public dbPort!: number;
    public dbUser!: string;
    public dbPassword!: string;
    public dbDatabaseName!: string;
    public geoserverDbHost!: string;
    public geoserverUrl!: string;
    public geoserverName!: string;
    public geoserverPassword!: string;
    public geoserverVolumePath!: string;
    public sshHost!: string;
    public sshUser!: string;
    public sshPort!: number;
    public sshKeyName!: string;
    public isProtected!: boolean;
}
