
// vue
import { defineComponent, ref, onMounted } from 'vue';

// vue3-material
import { VmModal } from 'vue3-material';

// configs
import { accountsConfig } from '@/config/accounts-config';

// components
import AccountEditor from '@/views/admin/account-editor.vue';
import ClereTitle from '@/components/clere-title.vue';
import Confirm from '@/components/confirm.vue';
import { CrActions } from '@/components/grid-plugins';

// services
import { useAccountService } from '@/api/service/account.service';

// models
import { Account } from '@/api/model/account.model';

export default defineComponent({
    components: {
        AccountEditor,
        ClereTitle,
        Confirm,
    },
    setup() {
        // services
        const {
            accountSummaries,
            getAccountAll,
            getAccountSummaryAll,
            getAccount,
            createAccount,
            updateAccount,
            deleteAccount,
        } = useAccountService();

        // refs
        const accountEditorModal = ref<InstanceType<typeof VmModal>>();
        const confirmDeleteModal = ref<InstanceType<typeof VmModal>>();

        // properties
        const account = ref(new Account());
        const isEditing = ref(false);
        const isLoading = ref(true);

        // methods
        const openModal = () => {
            accountEditorModal.value.show();
        };

        const hideModal = () => {
            accountEditorModal.value.hide();
        };

        const hideDeleteModal = () => {
            confirmDeleteModal.value.hide();
        };

        const saveAccount = async () => {
            if (isEditing.value) {
                await updateAccount(account.value);
                account.value = new Account();
            } else {
                await createAccount(account.value);
                account.value = new Account();
            }
            hideModal();
            isEditing.value = false;
        };

        const editAccount = async (a: Account) => {
            if (a && a.id) {
                const existingAccount = await getAccount(a.id);
                account.value = existingAccount;
                isEditing.value = true;
            } else {
                account.value = new Account();
                isEditing.value = false;
            }
            accountEditorModal.value.show();
        };

        const showAccountDelete = async (a: Account) => {
            if (a && a.id) {
                const existingAccount = await getAccount(a.id);
                account.value = existingAccount;
                confirmDeleteModal.value.show();
            }
        };

        const deleteSelectedAccount = async () => {
            if (account.value.id) {
                await deleteAccount(account.value.id);
                hideDeleteModal();
            }
        };

        const addAccount = async () => {
            account.value = new Account();
            isEditing.value = false;
            openModal();
        };

        // lifecycle hooks
        onMounted(async () => {
            getAccountAll();
            await getAccountSummaryAll();
            isLoading.value = false;
        });

        // configs
        const gridConfig = [
            ...accountsConfig,
            {
                title: 'Actions',
                fitWidth: true,
                cellRenderer: {
                    component: CrActions,
                    exported: {
                        actions: [
                            {
                                icon: 'fal fa-pencil',
                                callback: editAccount,
                            },
                            {
                                icon: 'fal fa-trash-alt',
                                callback: showAccountDelete,
                            },
                        ],
                    },
                },
            },
        ];

        return {
            isEditing,
            accountSummaries,
            gridConfig,
            saveAccount,
            editAccount,
            addAccount,
            deleteAccount,
            deleteSelectedAccount,
            account,
            accountEditorModal,
            confirmDeleteModal,
            openModal,
            hideModal,
            hideDeleteModal,
            isLoading,
        };
    },
});
