// vue
import { ref } from 'vue';
import { useStore } from 'vuex';

// hooks
import { useAuthHeader } from './auth-header';

// axios
import { AxiosPromise } from 'axios';

// models
import { Account, AccountSummary } from '../model/account.model';

export const useAccountService = () => {
    // hooks
    const store = useStore();
    const authHeader = useAuthHeader();

    // properties
    const url = store.state.apiUrl;
    const accounts = ref<Account[]>([]);
    const accountSummaries = ref<AccountSummary[]>([]);

    // methods
    const getAccountAll = async (): Promise<AxiosPromise> => {
        const res = await authHeader.get(`${url}/account`);
        accounts.value = res.data;
        return res;
    };

    const getAccountSummary = async (
        accountId: number,
    ): Promise<AxiosPromise> => {
        const res = await authHeader.get(`${url}/account/${accountId}/summary`);
        return res;
    };

    const getAccountSummaryAll = async (): Promise<AxiosPromise> => {
        const res = await authHeader.get(`${url}/account/summary`);
        accountSummaries.value = res.data;
        return res;
    };

    const getAccount = async (accountId: number): Promise<Account> => {
        const res = await authHeader.get(`${url}/account/${accountId}`);
        return res.data;
    };

    const getAccountInfo = async (): Promise<AxiosPromise> => {
        const res = await authHeader.get(`${url}/account-info`);
        return res;
    };

    const createAccount = async (account: Account): Promise<AxiosPromise> => {
        const res = await authHeader.post(`${url}/account`, account);
        getAccountAll();
        getAccountSummaryAll();
        return res;
    };

    const updateAccount = async (account: Account): Promise<AxiosPromise> => {
        const res = await authHeader.put(`${url}/account`, account);
        getAccountAll();
        getAccountSummaryAll();
        return res;
    };

    const deleteAccount = async (accountId: number): Promise<AxiosPromise> => {
        const res = await authHeader.deleteId(`${url}/account/${accountId}`);
        getAccountAll();
        getAccountSummaryAll();
        return res;
    };

    return {
        accounts,
        accountSummaries,
        getAccount,
        getAccountAll,
        getAccountSummaryAll,
        getAccountSummary,
        getAccountInfo,
        createAccount,
        updateAccount,
        deleteAccount,
    };
};
