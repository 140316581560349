
// vue
import { defineComponent, ref, onMounted } from 'vue';

// components
import ClereTitle from '@/components/clere-title.vue';
import UploadChip from '@/components/upload-chip.vue';

export default defineComponent({
    components: {
        ClereTitle,
        UploadChip,
    },
    setup() {
        // refs
        const fileUpload = ref<null | HTMLInputElement>(null);

        // properties
        const isLoading = ref(true);
        const files = ref<File[]>([]);

        // methods
        const chooseFiles = () => {
            if (fileUpload.value) {
                fileUpload.value.click();
            }
        };

        const handleFileUpload = () => {
            if (fileUpload.value && fileUpload.value.files) {
                const uploadFiles = fileUpload.value.files;
                const newFiles: any = [];
                for (let i = 0; i < uploadFiles.length; i++) {
                    newFiles.push(uploadFiles[i]);
                }
                newFiles.forEach((file: File) => {
                    files.value.push(file);
                });
            }
        };

        const clearFile = (i: number) => {
            files.value.splice(i, 1);
        };

        // lifecycle hooks
        onMounted(async () => {
            isLoading.value = false;
        });

        return {
            fileUpload,
            isLoading,
            chooseFiles,
            handleFileUpload,
            files,
            clearFile,
        };
    },
});
