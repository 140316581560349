
// vue
import { defineComponent } from 'vue';

export default defineComponent({
    props: ['closeModal', 'confirm', 'payload', 'primaryColor'],
    setup(props: any) {
        return {
            props,
        };
    },
});
