export class CourseMapboxLayer {
    public id?: number;
    public CourseSchemaid?: number;
    public LayerTableName?: string;
    public style?: string;
    public columns?: string[];
    public LayerCategoryId?: number;
    public IsDefault?: boolean;
    public LayerOrder?: number;
}

export class CourseMapboxLayer2 {
    public id?: number;
    public CourseSchemaid?: number;
    public LayerTableName?: string;
    public style?: JSON;
    public columns?: string[];
    public LayerCategoryId?: number;
    public IsDefault?: boolean;
    public LayerOrder?: number;
}
