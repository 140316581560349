import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-685e573e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vm_text_field = _resolveComponent("vm-text-field")!
  const _component_vm_switch = _resolveComponent("vm-switch")!
  const _component_vm_button = _resolveComponent("vm-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_vm_text_field, {
      class: "mt-4",
      variant: "outlined",
      label: "Course Schema Id",
      modelValue: _ctx.props.tileserverConfig.courseSchemaId,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.props.tileserverConfig.courseSchemaId) = $event)),
      modelModifiers: { number: true },
      type: "number"
    }, null, 8, ["modelValue"]),
    _createVNode(_component_vm_text_field, {
      class: "mt-4",
      variant: "outlined",
      label: "Course Name",
      modelValue: _ctx.props.tileserverConfig.courseName,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.props.tileserverConfig.courseName) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_vm_text_field, {
      class: "mt-4",
      variant: "outlined",
      label: "Key",
      modelValue: _ctx.props.tileserverConfig.key,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.props.tileserverConfig.key) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_vm_text_field, {
      class: "mt-4",
      variant: "outlined",
      label: "Mbtiles",
      modelValue: _ctx.props.tileserverConfig.mbtiles,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.props.tileserverConfig.mbtiles) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_vm_text_field, {
      class: "mt-4",
      variant: "outlined",
      label: "Display Name",
      modelValue: _ctx.props.tileserverConfig.displayName,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.props.tileserverConfig.displayName) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_vm_text_field, {
      class: "mt-4",
      variant: "outlined",
      label: "Description",
      modelValue: _ctx.props.tileserverConfig.description,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.props.tileserverConfig.description) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_vm_text_field, {
      class: "mt-4",
      variant: "outlined",
      label: "Mosaic Order",
      modelValue: _ctx.props.tileserverConfig.mosaicOrder,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.props.tileserverConfig.mosaicOrder) = $event)),
      modelModifiers: { number: true },
      type: "number"
    }, null, 8, ["modelValue"]),
    _createVNode(_component_vm_switch, {
      class: "mt-4 mb-4",
      label: "Is Pinzone Mosaic",
      modelValue: _ctx.props.tileserverConfig.isPinzoneMosaic,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.props.tileserverConfig.isPinzoneMosaic) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_vm_switch, {
      class: "mt-4 mb-4",
      label: "Is Player App Mosaic",
      modelValue: _ctx.props.tileserverConfig.isPlayerAppMosaic,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.props.tileserverConfig.isPlayerAppMosaic) = $event))
    }, null, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_vm_button, {
        class: "mr-2",
        variant: "text",
        primaryColor: "var(--triadic2-600)",
        onClick: _ctx.closeCallback
      }, {
        default: _withCtx(() => [
          _createTextVNode(" CANCEL ")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_vm_button, {
        class: "mr-2",
        primaryColor: "var(--triadic2-600)",
        onClick: _ctx.saveCallback
      }, {
        default: _withCtx(() => [
          _createTextVNode(" SAVE ")
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}