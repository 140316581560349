import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "upload" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_clere_title = _resolveComponent("clere-title")!
  const _component_vm_button = _resolveComponent("vm-button")!
  const _component_vm_card_content = _resolveComponent("vm-card-content")!
  const _component_vm_card = _resolveComponent("vm-card")!
  const _component_vm_container = _resolveComponent("vm-container")!

  return (_openBlock(), _createBlock(_component_vm_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_clere_title, null, {
        default: _withCtx(() => [
          _createTextVNode(" Download config ")
        ]),
        _: 1
      }),
      _createVNode(_component_vm_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_vm_card_content, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_vm_button, {
                  class: "mb-2",
                  primaryColor: "var(--color-primary)",
                  onClick: _ctx.downloadFile
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Download Config File ")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}