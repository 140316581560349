import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-652663fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vm_action_button = _resolveComponent("vm-action-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.exported.actions, (action, i) => {
      return (_openBlock(), _createBlock(_component_vm_action_button, {
        key: i,
        class: _normalizeClass({ 'ml-1': i !== 0 }),
        icon: action.icon,
        elevation: 0,
        primaryColor: "rgba(0,0,0,0)",
        accentColor: "rgba(var(--default-color),0.8)",
        isSmall: true,
        onClick: ($event: any) => (action.callback($setup.props.row))
      }, null, 8, ["class", "icon", "onClick"]))
    }), 128))
  ]))
}