
// vue
import { defineComponent, ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';

// vue3-material
import { VmModal } from 'vue3-material';

// hooks
import { useToast } from 'vue-toastification';

// components
import ClereTitle from '@/components/clere-title.vue';
import Dropdown from '@/components/dropdown.vue';
import Confirm from '@/components/confirm.vue';

// services
import { useDataMigrationService } from '@/api/service/data-migration-service';

// configs
import { migrationHistoryConfig } from '@/config/migration-history-config.js';
import { useEnvironmentService } from '@/api/service/environment.service';

export default defineComponent({
    components: {
        ClereTitle,
        Dropdown,
        Confirm,
    },
    setup() {
        // hooks
        const store = useStore();
        const toast = useToast();

        // services
        const {
            dataMigrations,
            dataMigrationSummaries,
            historicDataMigrations,
            getDataMigrationAll,
            getDataMigrationSummaryAll,
            getHistoricDataMigrations,
            migrateData,
            declineMigrateData,
        } = useDataMigrationService();

        const { environments, getEnvironmentAll } = useEnvironmentService();

        // refs
        const authorizeModal = ref<InstanceType<typeof VmModal>>();
        const declineModal = ref<InstanceType<typeof VmModal>>();

        // properties
        const modalTitle = 'Confirmation';
        const currentDataMigrationId = ref(0);
        const currentPage = ref(1);
        const rowsPerPage = 3;
        const isLoading = ref(true);
        const isGridLoading = ref(true);
        const isPushLoading = ref(false);

        // computed
        const totalPages = computed(() => {
            return Math.ceil(dataMigrationSummaries.value.length / rowsPerPage);
        });

        const startRowIndex = computed(() => {
            return Math.ceil((currentPage.value - 1) * rowsPerPage);
        });

        const endRowIndex = computed(() => {
            let endRow = Math.ceil(currentPage.value * rowsPerPage - 1);
            if (endRow > dataMigrationSummaries.value.length) {
                endRow = dataMigrationSummaries.value.length - 1;
            }
            return endRow;
        });

        const filteredDataMigrationSummaries = computed(() => {
            const tmpRows = [...dataMigrationSummaries.value];
            return tmpRows.splice(startRowIndex.value, rowsPerPage);
        });

        // methods
        const refreshHistory = async () => {
            isGridLoading.value = true;
            await getHistoricDataMigrations();
            isGridLoading.value = false;
        };

        const getEnvironmentName = (id: number): string => {
            const tempName = environments.value.find((env) => env.id === id);
            if (tempName) {
                return tempName.name;
            }
            return '';
        };

        const openAuthorizeModal = (dataMigrationId: number) => {
            currentDataMigrationId.value = dataMigrationId;
            authorizeModal.value.show();
        };

        const closeAuthorizeModal = () => {
            authorizeModal.value.hide();
        };

        const openDeclineModal = (dataMigrationId: number) => {
            currentDataMigrationId.value = dataMigrationId;
            declineModal.value.show();
        };

        const closeDeclineModal = () => {
            declineModal.value.hide();
        };

        const authorizeMigration = async () => {
            if (currentDataMigrationId.value) {
                isPushLoading.value = true;
                try {
                    await migrateData(currentDataMigrationId.value);
                    toast.success('Successfully migrated data');
                } catch (e) {
                    window.console.log(e);
                }
                isPushLoading.value = false;
                setTimeout(() => {
                    closeAuthorizeModal();
                });
                isGridLoading.value = true;
                refreshHistory();
            }
        };

        const declineMigration = async () => {
            if (currentDataMigrationId.value) {
                isPushLoading.value = true;
                try {
                    await declineMigrateData(currentDataMigrationId.value);
                    toast.success('Successfully declined migration request');
                } catch (e) {
                    window.console.log(e);
                }
                isPushLoading.value = false;
                setTimeout(() => {
                    closeDeclineModal();
                });
                isGridLoading.value = true;
                refreshHistory();
            }
        };

        const setCurrentPage = (pageIndex: number) => {
            if (pageIndex > 0 && pageIndex <= totalPages.value) {
                currentPage.value = pageIndex;
            }
        };

        const incrementCurrentPage = (): void => {
            setCurrentPage(currentPage.value + 1);
        };

        const decrementCurrentPage = (): void => {
            setCurrentPage(currentPage.value - 1);
        };

        const goToLastPage = (): void => {
            setCurrentPage(totalPages.value);
        };

        const goToFirstPage = (): void => {
            setCurrentPage(1);
        };

        // lifecycle hooks
        onMounted(async () => {
            await getDataMigrationSummaryAll();
            isLoading.value = false;

            await getEnvironmentAll();
            await getDataMigrationAll();
            refreshHistory();
        });

        return {
            store,
            dataMigrations,
            dataMigrationSummaries,
            historicDataMigrations,
            migrationHistoryConfig,
            environments,
            getEnvironmentName,
            authorizeMigration,
            declineMigration,
            authorizeModal,
            declineModal,
            openAuthorizeModal,
            closeAuthorizeModal,
            openDeclineModal,
            closeDeclineModal,
            modalTitle,
            currentDataMigrationId,
            filteredDataMigrationSummaries,
            totalPages,
            startRowIndex,
            endRowIndex,
            incrementCurrentPage,
            decrementCurrentPage,
            goToLastPage,
            goToFirstPage,
            isLoading,
            isGridLoading,
            isPushLoading,
        };
    },
});
