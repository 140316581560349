
// vue
import { defineComponent } from 'vue';

export default defineComponent({
    props: ['courseMapboxLayer', 'saveCallback', 'closeCallback'],
    emits: ['update:courseMapboxLayer'],
    setup(props) {
        return {
            props,
        };
    },
});
