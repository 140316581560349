
// vue
import { defineComponent, ref, onBeforeMount } from 'vue';

// axios
import axios from 'axios';

export default defineComponent({
    setup() {
        // properties
        const developers = ref([
            { name: 'David', id: 7971948, issues: [] },
            { name: 'Jake', id: 8160169, issues: [] },
            { name: 'Jamie', id: 2389804, issues: [] },
        ]);
        const testing = ref([]);
        const isFullscreen = ref(false);
        const issueTracker = ref<null | HTMLElement>(null);

        // methods
        const toggleFullscreen = () => {
            // isFullscreen.value = window.innerHeight === screen.height;
            if (isFullscreen.value) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                }
            } else {
                const elem = issueTracker.value;
                if (elem) {
                    if (elem.requestFullscreen) {
                        elem.requestFullscreen();
                    }
                }
            }
            isFullscreen.value = !isFullscreen.value;
        };

        const retrieveGitlabData = async () => {
            const milestone = (
                await axios.get(
                    'https://gitlab.com/api/v4/groups/clere-golf-hub/milestones?private_token=AyQ2pMwzjgC2aFbcNgUu&state=active',
                )
            ).data[0];

            let newTesting = [];

            newTesting = (
                await axios.get(
                    `https://gitlab.com/api/v4/groups/clere-golf-hub/issues?private_token=AyQ2pMwzjgC2aFbcNgUu&labels=Testing&scope=all&state=opened`,
                )
            ).data;

            newTesting = newTesting.concat(
                (
                    await axios.get(
                        `https://gitlab.com/api/v4/groups/clere-golf-hub/issues?private_token=AyQ2pMwzjgC2aFbcNgUu&labels=UAT&scope=all&state=opened`,
                    )
                ).data,
            );

            testing.value = newTesting;

            developers.value.forEach((developer) => {
                (async () => {
                    let newIssues = [];
                    newIssues = (
                        await axios.get(
                            `https://gitlab.com/api/v4/groups/clere-golf-hub/issues?private_token=AyQ2pMwzjgC2aFbcNgUu&labels=${milestone.title}&scope=all&assignee_id=${developer.id}&order_by=relative_position&state=opened`,
                        )
                    ).data;
                    newIssues = newIssues.concat(
                        (
                            await axios.get(
                                `https://gitlab.com/api/v4/groups/clere-golf-hub/issues?private_token=AyQ2pMwzjgC2aFbcNgUu&labels=To+Be+Deployed&scope=all&assignee_id=${developer.id}&order_by=relative_position&state=opened`,
                            )
                        ).data,
                    );
                    newIssues = newIssues.concat(
                        (
                            await axios.get(
                                `https://gitlab.com/api/v4/groups/clere-golf-hub/issues?private_token=AyQ2pMwzjgC2aFbcNgUu&labels=To+Be+Merged&scope=all&assignee_id=${developer.id}&order_by=relative_position&state=opened`,
                            )
                        ).data,
                    );
                    developer.issues = newIssues;
                })();
            });
        };

        // lifecycle hooks
        onBeforeMount(() => {
            const updateGitlabDataRecursive = async () => {
                await retrieveGitlabData();
                setTimeout(updateGitlabDataRecursive, 10000);
            };
            updateGitlabDataRecursive();
        });

        return {
            developers,
            isFullscreen,
            toggleFullscreen,
            issueTracker,
            testing,
        };
    },
});
