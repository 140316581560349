
// vue
import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: ['title'],
    setup(props: any) {
        // properties
        const isOpen = ref(false);

        // methods
        const toggle = () => {
            isOpen.value = !isOpen.value;
        };

        return {
            props,
            toggle,
            isOpen,
        };
    },
});
