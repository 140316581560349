import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "row jc-center"
}
const _hoisted_2 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_clere_title = _resolveComponent("clere-title")!
  const _component_vm_card_content = _resolveComponent("vm-card-content")!
  const _component_vm_card = _resolveComponent("vm-card")!
  const _component_vm_loading_spinner = _resolveComponent("vm-loading-spinner")!
  const _component_environment_card = _resolveComponent("environment-card")!
  const _component_vm_container = _resolveComponent("vm-container")!

  return (_openBlock(), _createBlock(_component_vm_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_clere_title, null, {
        default: _withCtx(() => [
          _createTextVNode(" Course data migration ")
        ]),
        _: 1
      }),
      (_ctx.environments.length === 0 && !_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_vm_card, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_vm_card_content, null, {
                default: _withCtx(() => [
                  _createTextVNode(" No environments available ")
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_vm_loading_spinner)
          ]))
        : _createCommentVNode("", true),
      (!_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.environments, (environment) => {
              return (_openBlock(), _createBlock(_component_vm_card, {
                class: "mb-4",
                key: environment.id
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_vm_card_content, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_environment_card, {
                        environments: _ctx.environments,
                        environment: environment
                      }, null, 8, ["environments", "environment"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}