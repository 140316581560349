// vue
import { ref } from 'vue';
import { useStore } from 'vuex';

// axios
import { AxiosPromise } from 'axios';

// hooks
import { useAuthHeader } from './auth-header';

// models
import { Permission } from '../model/permission.model';

export const usePermissionService = () => {
    // hooks
    const store = useStore();
    const authHeader = useAuthHeader();

    // properties
    const url = store.state.apiUrl;
    const permissions = ref<Permission[]>([]);

    // methods
    const getPermissionAll = async (): Promise<AxiosPromise> => {
        const res = await authHeader.get(`${url}/permission`);
        permissions.value = res.data;
        return res;
    };

    return {
        permissions,
        getPermissionAll,
    };
};
