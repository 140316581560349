
// vue
import { defineComponent, onMounted, ref, computed } from 'vue';
import { useToast } from 'vue-toastification';

// vue3-material
import { VmModal } from 'vue3-material';

// components
import PushCourses from './push-courses.vue';
import Dropdown from '@/components/dropdown.vue';

// services
import { useCourseSchemaService } from '@/api/service/course-schema.service';

// configs
import { courseSchemaConfig } from '@/config/course-schema-config';

export default defineComponent({
    components: {
        PushCourses,
        Dropdown,
    },
    props: ['environment', 'environments'],
    setup(props: any) {
        // hooks
        const toast = useToast();

        // services
        const {
            courseSchemas,
            getCourseSchema,
            updateCache,
            clearCache,
            deleteGreenData,
        } = useCourseSchemaService();

        // refs
        const courseSchemaGrid = ref(null);
        const dataMigrationModal = ref<InstanceType<typeof VmModal>>();

        // properties
        const courseSchemaSelect = ref<any[]>([]);
        const selectedCourseIds = ref<number[]>([]);
        const isLoading = ref(true);
        const isPushLoading = ref(false);

        // computed
        const coursesSelectedCount = computed(() => {
            return (courseSchemaGrid.value as any).getSelectedIds().length;
        });

        // methods
        const openModal = () => {
            selectedCourseIds.value = (courseSchemaGrid.value as any).getSelectedIds();
            dataMigrationModal.value.show();
        };

        const closeModal = () => {
            dataMigrationModal.value.hide();
        };

        const pushStartedCallback = () => {
            isPushLoading.value = true;
        };

        const pushFinishedCallback = () => {
            isPushLoading.value = false;
            (courseSchemaGrid.value as any).deselectAll();
            setTimeout(() => {
                closeModal();
            });
        };
        const ClearSelection = () => {
            selectedCourseIds.value = [];
            if (courseSchemaGrid.value) {
                (courseSchemaGrid.value as any).deselectAll();
            }
        };

        const ClearCache = async () => {
            selectedCourseIds.value = (courseSchemaGrid.value as any).getSelectedIds();

            try {
                await clearCache(selectedCourseIds.value[0]);
                toast.success('Successfully cleared Cache');
            } catch (e) {
                toast.error('Failed to clear Cache');
            }
        };

        const DeleteGreenData = async () => {
            selectedCourseIds.value = (courseSchemaGrid.value as any).getSelectedIds();
            const environmentId = props.environment.id;

            try {
                await deleteGreenData(
                    selectedCourseIds.value[0],
                    environmentId,
                );
                toast.success('Successfully cleared Green Data');
            } catch (e) {
                toast.error('Failed to clear Green Data');
            }
        };

        const UpdateCache = async () => {
            selectedCourseIds.value = (courseSchemaGrid.value as any).getSelectedIds();

            try {
                await updateCache(selectedCourseIds.value[0]);
                toast.success('Successfully migrated data');
            } catch (e) {
                toast.error('Failed to update Cache');
            }
        };

        // lifecycle hooks
        onMounted(async () => {
            await getCourseSchema(props.environment.id);
            isLoading.value = false;
        });

        return {
            props,
            courseSchemas,
            courseSchemaGrid,
            courseSchemaSelect,
            coursesSelectedCount,
            courseSchemaConfig,
            selectedCourseIds,
            dataMigrationModal,
            openModal,
            closeModal,
            pushStartedCallback,
            pushFinishedCallback,
            isLoading,
            isPushLoading,
            ClearCache,
            UpdateCache,
            DeleteGreenData,
            ClearSelection,
        };
    },
});
