
// vue
import { defineComponent, onMounted } from 'vue';

// services
import { usePermissionService } from '@/api/service/permission.service';

export default defineComponent({
    props: [
        'role',
        'saveCallback',
        'closeCallback',
        'permArrayToggle',
        'roleName',
    ],
    setup(props: any) {
        // services
        const { permissions, getPermissionAll } = usePermissionService();

        // methods
        const permissionName = (name: string) => {
            name = name.substring(3);
            const result = name.replace(/([A-Z])/g, ' $1');
            const finalResult =
                result.charAt(0).toUpperCase() + result.slice(1);
            return finalResult;
        };

        // lifecycle hooks
        onMounted(async () => {
            getPermissionAll();
        });

        return {
            props,
            permissions,
            permissionName,
        };
    },
});
