// vue
import { ref } from 'vue';
import { useStore } from 'vuex';

// axios
import { AxiosPromise } from 'axios';

// hooks
import { useAuthHeader } from './auth-header';

// models
import { TileserverConfig } from '../model/tileserver-config.model';

export const useTileserverConfigService = () => {
    // hooks
    const store = useStore();
    const authHeader = useAuthHeader();

    // properties
    const url = store.state.apiUrl;
    const tileserverConfigs = ref<TileserverConfig[]>([]);

    // methods
    const getTileserverConfigAll = async (): Promise<AxiosPromise> => {
        const res = await authHeader.get(`${url}/tileserver-config`);
        tileserverConfigs.value = res.data;
        return res;
    };

    const getTileserverConfig = async (
        id: number,
    ): Promise<TileserverConfig> => {
        const res = await authHeader.get(`${url}/tileserver-config/${id}`);
        return res.data;
    };

    const createTileserverConfig = async (
        tileserverConfig: TileserverConfig,
    ): Promise<AxiosPromise> => {
        const res = await authHeader.post(
            `${url}/tileserver-config`,
            tileserverConfig,
        );
        getTileserverConfigAll();
        return res;
    };

    const updateTileserverConfig = async (
        tileserverConfig: TileserverConfig,
    ): Promise<AxiosPromise> => {
        const res = await authHeader.put(
            `${url}/tileserver-config`,
            tileserverConfig,
        );
        getTileserverConfigAll();
        return res;
    };

    const deleteTileserverConfig = async (
        id: number,
    ): Promise<AxiosPromise> => {
        const res = await authHeader.deleteId(`${url}/tileserver-config/${id}`);
        getTileserverConfigAll();
        return res;
    };

    return {
        tileserverConfigs,
        getTileserverConfig,
        getTileserverConfigAll,
        createTileserverConfig,
        updateTileserverConfig,
        deleteTileserverConfig,
    };
};
