
// vue
import { computed } from 'vue';

export default {
    props: ['row', 'field', 'exported'],
    // eslint-disable-next-line
    setup(props: any) {
        // computed
        const dateTimeString = computed(() => {
            const dateTime = new Date(props.row[props.field]);
            let dateTimeString = '';

            dateTimeString +=
                ('000' + dateTime.getDate()).slice(-2) +
                '/' +
                ('000' + (dateTime.getMonth() + 1)).slice(-2) +
                '/' +
                dateTime.getFullYear() +
                ' at ' +
                ('000' + dateTime.getHours()).slice(-2) +
                ':' +
                ('000' + dateTime.getMinutes()).slice(-2);

            return dateTimeString;
        });

        return {
            props,
            dateTimeString,
        };
    },
};
