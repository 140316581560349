export class DataMigration {
    public id?: number;
    public sourceEnvironmentId!: number;
    public targetEnvironmentId!: number;
    public courseIdArray!: number[];
    public requestedByAccountId!: number[];
    public authorizedByAccountId!: number | null;
    public isCompleted!: boolean;
    public isAuthorized!: boolean;
}

export class DataMigrationSummaries {
    public id?: number;
    public sourceEnvironmentId?: number;
    public sourceEnvironmentName?: string;
    public targetEnvironmentName?: string;
    public courseSchemaIds?: number[];
    public courseSchemaNames?: string[];
    public isCompleted?: boolean;
    public isAuthorized?: boolean;
}

export class HistoricDataMigrations {
    public id?: number;
    public date?: string;
    public migrationSummary?: string;
    public requestedByAccountName?: string;
    public authorizedByAccountId?: string;
    public isCompleted?: boolean;
    public isAuthorized?: boolean;
}
