import { ref } from 'vue';
import { useStore } from 'vuex';

import { useAuthHeader } from './auth-header';
import { AxiosPromise } from 'axios';

import {
    DataMigration,
    DataMigrationSummaries,
    HistoricDataMigrations,
} from '@/api/model/data-migration.model';

export const useDataMigrationService = () => {
    const store = useStore();
    const url = store.state.apiUrl;

    const authHeader = useAuthHeader();

    const dataMigrations = ref<DataMigration[]>([]);
    const dataMigrationSummaries = ref<DataMigrationSummaries[]>([]);
    const historicDataMigrations = ref<HistoricDataMigrations[]>([]);

    const getDataMigrationAll = async (): Promise<AxiosPromise> => {
        const res = await authHeader.get(`${url}/data-migration`);
        dataMigrations.value = res.data;
        return res;
    };

    const getDataMigration = async (
        dataMigrationId: number,
    ): Promise<AxiosPromise> => {
        const res = await authHeader.get(
            `${url}/data-migration/${dataMigrationId}`,
        );
        return res;
    };

    const getDataMigrationSummaryAll = async (): Promise<AxiosPromise> => {
        const res = await authHeader.get(`${url}/data-migration/summary`);
        dataMigrationSummaries.value = res.data;
        return res;
    };

    const getHistoricDataMigrations = async (): Promise<AxiosPromise> => {
        const res = await authHeader.get(`${url}/data-migration/history`);
        historicDataMigrations.value = res.data;
        return res;
    };

    const createDataMigration = async (
        dataMigration: DataMigration,
    ): Promise<AxiosPromise> => {
        const res = await authHeader.post(
            `${url}/data-migration`,
            dataMigration,
        );
        getDataMigrationAll();
        return res;
    };

    const updateDataMigration = async (
        dataMigration: DataMigration,
    ): Promise<AxiosPromise> => {
        const res = await authHeader.put(
            `${url}/data-migration`,
            dataMigration,
        );
        getDataMigrationSummaryAll();
        getHistoricDataMigrations();
        return res;
    };

    const deleteDataMigration = async (
        dataMigrationId: number,
    ): Promise<AxiosPromise> => {
        const res = await authHeader.deleteId(
            `${url}/data-migration/${dataMigrationId}`,
        );
        getDataMigrationAll();
        return res;
    };

    const migrateData = async (
        dataMigrationId: number,
    ): Promise<AxiosPromise> => {
        const res = await authHeader.get(
            `${url}/data-migration/${dataMigrationId}/migrate`,
        );
        await getDataMigrationAll();
        await getDataMigrationSummaryAll();
        await getHistoricDataMigrations();
        return res;
    };

    const declineMigrateData = async (
        dataMigrationId: number,
    ): Promise<AxiosPromise> => {
        const res = await authHeader.get(
            `${url}/data-migration/${dataMigrationId}/decline-migrate`,
        );
        await getDataMigrationAll();
        await getDataMigrationSummaryAll();
        await getHistoricDataMigrations();
        return res;
    };

    return {
        dataMigrations,
        dataMigrationSummaries,
        historicDataMigrations,
        getDataMigration,
        getDataMigrationSummaryAll,
        getHistoricDataMigrations,
        getDataMigrationAll,
        createDataMigration,
        updateDataMigration,
        deleteDataMigration,
        migrateData,
        declineMigrateData,
    };
};
