
// vue
import { defineComponent } from 'vue';

export default defineComponent({
    props: ['clearFile', 'index'],
    setup() {
        return {};
    },
});
