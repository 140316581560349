import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-427269e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "confirm-container" }
const _hoisted_2 = { class: "lg-text center-text" }
const _hoisted_3 = { class: "row jc-flex-end mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vm_button = _resolveComponent("vm-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_vm_button, {
        primaryColor: _ctx.primaryColor,
        variant: "text",
        elevation: null,
        onClick: _ctx.closeModal
      }, {
        default: _withCtx(() => [
          _createTextVNode(" CANCEL ")
        ]),
        _: 1
      }, 8, ["primaryColor", "onClick"]),
      _createVNode(_component_vm_button, {
        class: "ml-2",
        primaryColor: _ctx.primaryColor,
        elevation: null,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.confirm(_ctx.payload)))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" CONFIRM ")
        ]),
        _: 1
      }, 8, ["primaryColor"])
    ])
  ]))
}