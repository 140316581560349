
export default {
    props: ['exported', 'row', 'field'],
    // eslint-disable-next-line
    setup(props: any) {
        return {
            props,
        };
    },
};
