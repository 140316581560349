// vue
import { ref } from 'vue';
import { useStore } from 'vuex';

// axios
import { AxiosPromise } from 'axios';

// hooks
import { useAuthHeader } from './auth-header';

// models
import { CourseMapboxLayer } from '@/api/model/course-mapbox-layer.model';

export const useCourseMapboxLayerService = () => {
    // hooks
    const store = useStore();
    const authHeader = useAuthHeader();

    // properties
    const url = store.state.apiUrl;
    const courseMapboxLayers = ref<CourseMapboxLayer[]>([]);

    // methods

    const getCourseMapboxLayer = async (id: number): Promise<AxiosPromise> => {
        const res = await authHeader.get(
            `${url}/environment/${id}/course-mapbox-layer`,
        );

        return res.data;
    };

    const addCourseMapboxLayer = async (
        environmentId: number,
        courseMapboxLayer: any,
    ): Promise<AxiosPromise> => {
        const res = await authHeader.post(
            `${url}/environment/${environmentId}/course-mapbox-layer`,
            courseMapboxLayer,
        );
        await getCourseMapboxLayer(environmentId);
        return res;
    };

    const deleteCourseMapboxLayer = async (
        id: number,
        environmentId: number,
    ): Promise<AxiosPromise> => {
        const res = await authHeader.deleteId(
            `${url}/environment/${environmentId}/course-mapbox-layer/${id}`,
        );
        await getCourseMapboxLayer(environmentId);
        return res;
    };

    const updateCourseMapboxLayer = async (
        environmentId: number,
        courseMapboxLayer: any,
    ): Promise<AxiosPromise> => {
        const res = await authHeader.put(
            `${url}/environment/${environmentId}/course-mapbox-layer`,
            courseMapboxLayer,
        );
        await getCourseMapboxLayer(environmentId);
        return res;
    };
    return {
        courseMapboxLayers,
        getCourseMapboxLayer,
        addCourseMapboxLayer,
        updateCourseMapboxLayer,
        deleteCourseMapboxLayer,
    };
};
