// vue
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

// axios
import axios, { AxiosPromise } from 'axios';

// hooks
import { useAuthHeader } from './auth-header';
import { useToast } from 'vue-toastification';

// models
import { LoginCredentials } from '@/api/model/login.model';
import { Token } from '@/api/model/token.model';

export const useAuthService = () => {
    // hooks
    const store = useStore();
    const router = useRouter();
    const toast = useToast();
    const authHeader = useAuthHeader();

    // properties
    const url = store.state.apiUrl;

    // methods
    const getVersion = async (): Promise<AxiosPromise> => {
        const res = await authHeader.get(`${url}/version`);
        return res;
    };

    const deleteToken = (token: Token): AxiosPromise<AxiosPromise> => {
        const axiosInstance = authHeader.deleteObject(`${url}/auth`, token);
        return axiosInstance;
    };

    const logIn = async (
        logInCredentials: LoginCredentials,
    ): Promise<AxiosPromise> => {
        try {
            const res = await axios.post(`${url}/log-in`, logInCredentials);
            store.commit('setAuthToken', res.data.authToken);
            store.commit('setAccountInfo', res.data.accountInfo);
            return res;
        } catch (e) {
            toast.error('Invalid email or password');
            throw e;
        }
    };

    const logOut = async () => {
        try {
            await deleteToken(store.state.authToken);
            store.commit('removeAuthToken');
            store.commit('removeAccountInfo');
            router.push({ path: '/' });
        } catch (e) {
            if (e.response.data.message) {
                toast.error(e.response.data.message);
            } else {
                toast.error(JSON.stringify(e));
            }
            throw e;
        }
    };

    return {
        getVersion,
        deleteToken,
        logIn,
        logOut,
    };
};
