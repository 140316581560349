// vue
import { ref } from 'vue';
import { useStore } from 'vuex';

// axios
import { AxiosPromise } from 'axios';

// servies
import { useAuthHeader } from './auth-header';

// models
import { Role } from '../model/role.model';

export const useRoleService = () => {
    // hooks
    const store = useStore();
    const authHeader = useAuthHeader();

    // properties
    const url = store.state.apiUrl;
    const roles = ref<Role[]>([]);

    // methods
    const getRoleAll = async (): Promise<AxiosPromise> => {
        if (store.state.accountInfo.permissions.indexOf('canReadRole')) {
            const res = await authHeader.get(`${url}/role`);
            roles.value = res.data;
            return res;
        }
        return new Promise(() => {
            return null;
        });
    };

    const getRole = async (id: number): Promise<Role> => {
        const res = await authHeader.get(`${url}/role/${id}`);
        return res.data;
    };

    const createRole = async (role: Role): Promise<AxiosPromise> => {
        const res = await authHeader.post(`${url}/role`, role);
        getRoleAll();
        return res;
    };

    const updateRole = async (role: Role): Promise<AxiosPromise> => {
        const res = await authHeader.put(`${url}/role`, role);
        getRoleAll();
        return res;
    };

    const deleteRole = async (id: number): Promise<AxiosPromise> => {
        const res = await authHeader.deleteId(`${url}/role/${id}`);
        getRoleAll();
        return res;
    };

    return {
        roles,
        getRole,
        getRoleAll,
        createRole,
        updateRole,
        deleteRole,
    };
};
