export const rolesConfig: any[] = [
    {
        title: 'Id',
        field: 'id',
    },
    {
        title: 'Name',
        field: 'name',
    },
];
