
// vue
import { defineComponent } from 'vue';

export default defineComponent({
    props: ['tileserverConfig', 'saveCallback', 'closeCallback'],
    setup(props: any) {
        return {
            props,
        };
    },
});
