import { CrBoolean } from '@/components/grid-plugins';

export const courseSchemaConfig: any[] = [
    {
        title: 'Id',
        field: 'id',
        fitWidth: true,
    },
    {
        title: 'Name',
        field: 'name',
    },
    {
        title: 'Display Name',
        field: 'displayName',
    },
    {
        title: 'Tag',
        field: 'tag',
    },
    {
        title: 'Country',
        field: 'countryCode',
    },
    {
        title: 'Player App',
        field: 'isPlayerApp',
        fitWidth: true,
        cellRenderer: {
            component: CrBoolean,
        },
    },
];
