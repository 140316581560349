
// vue
import { defineComponent } from 'vue';

export default defineComponent({
    props: ['environment', 'saveCallback', 'closeCallback', 'isEditing'],
    setup(props: any) {
        return {
            props,
        };
    },
});
