
// vue
import { defineComponent, onMounted } from 'vue';
import { useStore } from 'vuex';

// services
import { useRoleService } from '@/api/service/role.service';

export default defineComponent({
    props: ['account', 'saveCallback', 'closeCallback'],
    setup(props: any) {
        // hookes
        const store = useStore();

        // services
        const { roles, getRoleAll } = useRoleService();

        // lifecycle hooks
        onMounted(async () => {
            if (
                store.state.accountInfo.permissions.indexOf('canReadRole') !==
                -1
            ) {
                getRoleAll();
            }
        });

        return {
            props,
            roles,
        };
    },
});
