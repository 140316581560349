import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_clere_title = _resolveComponent("clere-title")!
  const _component_vm_button = _resolveComponent("vm-button")!
  const _component_vm_grid = _resolveComponent("vm-grid")!
  const _component_vm_card = _resolveComponent("vm-card")!
  const _component_vm_card_header = _resolveComponent("vm-card-header")!
  const _component_TileserverConfigEditor = _resolveComponent("TileserverConfigEditor")!
  const _component_vm_card_content = _resolveComponent("vm-card-content")!
  const _component_vm_modal = _resolveComponent("vm-modal")!
  const _component_vm_container = _resolveComponent("vm-container")!

  return (_openBlock(), _createBlock(_component_vm_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_clere_title, null, {
        default: _withCtx(() => [
          _createTextVNode(" Tileserver Configs ")
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_vm_button, {
          primaryColor: "var(--triadic2-600)",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModal()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" ADD TILESERVER CONFIG ")
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_vm_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_vm_grid, {
            config: _ctx.gridConfig,
            rowData: _ctx.tileserverConfigs,
            isLoading: _ctx.isLoading
          }, null, 8, ["config", "rowData", "isLoading"])
        ]),
        _: 1
      }),
      _createVNode(_component_vm_modal, { ref: "tileserverConfigEditorModal" }, {
        default: _withCtx(() => [
          _createVNode(_component_vm_card_header, null, {
            default: _withCtx(() => [
              _createTextVNode("Tileserver Config Editor")
            ]),
            _: 1
          }),
          _createVNode(_component_vm_card_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_TileserverConfigEditor, {
                isEditing: _ctx.isEditing,
                closeCallback: _ctx.hideModal,
                tileserverConfig: _ctx.tileserverConfig1,
                saveCallback: _ctx.saveTileserverConfig
              }, null, 8, ["isEditing", "closeCallback", "tileserverConfig", "saveCallback"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }))
}