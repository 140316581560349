
// vue
import { defineComponent, ref, onMounted } from 'vue';

// components
import ClereTitle from '@/components/clere-title.vue';

// services
import { useEnvironmentService } from '@/api/service/environment.service';

// hooks
import { useToast } from 'vue-toastification';

// vue3-material
// import { VmContainer, VmCard, VmCardContent, VmButton } from 'vue3-material';

export default defineComponent({
    components: {
        ClereTitle,
    },
    setup() {
        // services
        const {
            getConfigFile,
            updateConfigData,
            saveConfigFile,
        } = useEnvironmentService();

        // refs
        const isLoading = ref(true);

        // hooks
        const toast = useToast();

        // methods
        const downloadFile = async () => {
            const res = await getConfigFile();
            const mosaicData = res.data;
            const configList: any = {};

            mosaicData.keys.forEach((keys: any) => {
                mosaicData.mosaic.forEach((mosaic: any) => {
                    if (keys === mosaic.key) {
                        configList[keys] = {
                            mbtiles: mosaic.mbtiles,
                            tilejson: mosaic.tilejson,
                        };
                    }
                });
            });

            const mosaicJson = { data: configList };

            let mosaicStr = JSON.stringify(mosaicJson, null, 4);

            mosaicStr = 'trim' + mosaicStr + 'trim';

            const formData = new FormData();

            formData.append('json', mosaicStr);

            try {
                await saveConfigFile(formData);
                toast.success('Successfully updated Config Data');
            } catch (e) {}
        };

        const updateFile = async () => {
            try {
                await updateConfigData();
                toast.success('Successfully updated Config Data');
            } catch (e) {}
        };

        // lifecycle hooks
        onMounted(async () => {
            isLoading.value = false;
        });

        return {
            isLoading,
            downloadFile,
            updateFile,
        };
    },
});
