export const accountsConfig: any[] = [
    {
        title: 'Id',
        field: 'id',
    },
    {
        title: 'Name',
        field: 'fullName',
    },
    {
        title: 'Email',
        field: 'email',
    },
    {
        title: 'Role',
        field: 'roleName',
    },
];
