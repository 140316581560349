
// vue
import { defineComponent, onBeforeMount, ref, watch } from 'vue';

// components
import { VmModal } from 'vue3-material';
import { CrActions } from '@/components/grid-plugins';
import CourseSchemaEditor from '@/components/editor/course-schema-editor.vue';

// services
import { useEnvironmentService } from '@/api/service/environment.service';
import { useCourseSchemaService } from '@/api/service/course-schema.service';

// hooks
import { useToast } from 'vue-toastification';

// configs
import { courseSchemaConfig } from '@/config/course-schema-config';

// models
import { Course } from '@/api/model/course.model';

export default defineComponent({
    components: {
        CourseSchemaEditor,
    },
    setup() {
        // services
        const {
            courseSchemas,
            getCourseSchema,
            addCourseSchema,
            deleteCourseSchema,
            updateCourseSchema,
        } = useCourseSchemaService();
        const { environments, getEnvironmentAll } = useEnvironmentService();

        // hooks
        const toast = useToast();

        // refs
        const courseSchema1 = ref<Course>();

        const courseSchemaEditorModal = ref<InstanceType<typeof VmModal>>();

        const isLoading = ref(true);
        const isEditing = ref(false);
        const isLoadingEditor = ref(false);
        const isEnvSelected = ref(false);

        const selectedEnvironmentId = ref(0);

        // methods
        const openModal = (): void => {
            courseSchemaEditorModal.value.show();
        };

        const hideModal = (): void => {
            courseSchema1.value = new Course();
            isEditing.value = false;
            isLoadingEditor.value = false;
            courseSchemaEditorModal.value.hide();
        };

        const saveCourseSchema = async () => {
            if (!courseSchema1.value) return;

            if (
                !courseSchema1.value.name ||
                !courseSchema1.value.displayName ||
                !courseSchema1.value.countryCode
            ) {
                toast.error('Please fill all required fields.');
                return;
            }
            isLoadingEditor.value = true;

            isEditing.value
                ? await updateCourseSchema(
                      selectedEnvironmentId.value,
                      courseSchema1.value,
                  )
                : await addCourseSchema(
                      selectedEnvironmentId.value,
                      courseSchema1.value,
                  );

            toast.success('Course schema saved successfully.');

            hideModal();
        };

        const populateTableData = async (): Promise<void> => {
            if (selectedEnvironmentId.value === 0) return;

            isLoading.value = true;
            await getCourseSchema(selectedEnvironmentId.value);
            isLoading.value = false;
        };

        const resetTargetEnv = (): void => {
            selectedEnvironmentId.value = 0;
        };

        const showRoleDelete = async (courseSchema: any) => {
            isLoadingEditor.value = true;
            await deleteCourseSchema(
                courseSchema.id,
                selectedEnvironmentId.value,
            );
            toast.success('Course schema deleted successfully.');
            isLoadingEditor.value = false;
        };

        const editCourse = async (courseSchema: any) => {
            isEditing.value = true;
            courseSchema1.value = courseSchemas.value.find(
                (x) => x.id === courseSchema.id,
            );
            if (!courseSchema1.value) return;
            openModal();
        };

        // watch
        watch(
            selectedEnvironmentId,
            async (): Promise<void> => {
                isEnvSelected.value = !isEnvSelected.value;
                await populateTableData();
            },
        );

        // lifecycle
        onBeforeMount(async () => {
            isLoading.value = true;

            courseSchema1.value = new Course();
            await getEnvironmentAll();

            isLoading.value = false;
        });

        // configs
        const gridConfig = [
            ...courseSchemaConfig,
            {
                title: 'Actions',
                fitWidth: true,
                cellRenderer: {
                    component: CrActions,
                    exported: {
                        actions: [
                            {
                                icon: 'fal fa-pencil',
                                callback: editCourse,
                            },
                            {
                                icon: 'fal fa-trash-alt',
                                callback: showRoleDelete,
                            },
                        ],
                    },
                },
            },
        ];

        return {
            isEditing,
            hideModal,
            openModal,
            isLoading,
            gridConfig,
            courseSchema1,
            environments,
            isEnvSelected,
            courseSchemas,
            resetTargetEnv,
            isLoadingEditor,
            saveCourseSchema,
            courseSchemaConfig,
            selectedEnvironmentId,
            courseSchemaEditorModal,
        };
    },
});
