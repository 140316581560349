import { CrBoolean } from '@/components/grid-plugins';

export const environmentConfig: any[] = [
    {
        title: 'Id',
        field: 'id',
    },
    {
        title: 'Name',
        field: 'name',
    },
    {
        title: 'Tag',
        field: 'tag',
    },
    {
        title: 'Protected',
        field: 'isProtected',
        fitWidth: true,
        cellRenderer: {
            component: CrBoolean,
        },
    },
];
