import { CrBoolean } from '@/components/grid-plugins';

export const courseMapboxLayerConfig: any[] = [
    {
        title: 'Id',
        field: 'id',
        fitWidth: true,
    },
    {
        title: 'Course Schema Id',
        field: 'CourseSchemaid',
    },
    {
        title: 'Layer Table Name',
        field: 'LayerTableName',
    },
    {
        title: 'Style',
        field: 'style',
    },
    {
        title: 'Columns',
        field: 'columns',
    },
    {
        title: 'Layer Category Id',
        field: 'LayerCategoryId',
    },
    {
        title: 'Is Default',
        field: 'IsDefault',
        fitWidth: true,
        cellRenderer: {
            component: CrBoolean,
        },
    },
    {
        title: 'Layer Order',
        field: 'LayerOrder',
    },
];
