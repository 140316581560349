import { CrBoolean, CrDateTime } from '@/components/grid-plugins';

export const migrationHistoryConfig = [
    {
        title: 'Date',
        field: 'date',
        cellRenderer: {
            component: CrDateTime,
        },
    },
    {
        title: 'Migration',
        field: 'migrationSummary',
    },
    {
        title: 'Requested by',
        field: 'requestedByAccountName',
    },
    {
        title: 'Authorized by',
        field: 'authorizedByAccountName',
    },
    {
        title: 'Authorised',
        field: 'isAuthorized',
        fitWidth: true,
        cellRenderer: {
            component: CrBoolean,
        },
    },
];
