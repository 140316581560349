import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b1c2d22"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "row jc-space-between ai-center"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = {
  key: 2,
  class: "row-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vm_dropdown = _resolveComponent("vm-dropdown")!
  const _component_vm_button = _resolveComponent("vm-button")!
  const _component_vm_grid = _resolveComponent("vm-grid")!
  const _component_vm_card = _resolveComponent("vm-card")!
  const _component_vm_card_header = _resolveComponent("vm-card-header")!
  const _component_MapboxEditor = _resolveComponent("MapboxEditor")!
  const _component_vm_card_content = _resolveComponent("vm-card-content")!
  const _component_vm_modal = _resolveComponent("vm-modal")!
  const _component_vm_loading_spinner = _resolveComponent("vm-loading-spinner")!
  const _component_vm_container = _resolveComponent("vm-container")!

  return (_openBlock(), _createBlock(_component_vm_container, null, {
    default: _withCtx(() => [
      (!_ctx.isEnvSelected)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_vm_dropdown, {
              label: "Target Environment",
              title: "name",
              options: _ctx.environments,
              modelValue: _ctx.selectedEnvironmentId,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedEnvironmentId) = $event))
            }, null, 8, ["options", "modelValue"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_vm_button, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openModal())),
                primaryColor: "var(--triadic2-600)"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Add Mapbox Layer ")
                ]),
                _: 1
              }),
              _createVNode(_component_vm_button, {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.resetTargetEnv())),
                class: "ml-3 outlined-grey",
                primaryColor: "var(--triadic2-600)"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" RESET ")
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_vm_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_vm_grid, {
                  config: _ctx.gridConfig,
                  isLoading: _ctx.isLoading,
                  rowData: _ctx.mapboxData
                }, null, 8, ["config", "isLoading", "rowData"])
              ]),
              _: 1
            }),
            _createVNode(_component_vm_modal, { ref: "mapboxEditorModal" }, {
              default: _withCtx(() => [
                _createVNode(_component_vm_card_header, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Mapbox Layer Editor")
                  ]),
                  _: 1
                }),
                _createVNode(_component_vm_card_content, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_MapboxEditor, {
                      isEditing: _ctx.isEditing,
                      closeCallback: _ctx.hideModal,
                      courseMapboxLayer: _ctx.courseMapboxLayer1,
                      saveCallback: _ctx.saveCourseMapboxLayer
                    }, null, 8, ["isEditing", "closeCallback", "courseMapboxLayer", "saveCallback"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 512)
          ])),
      (_ctx.isLoadingEditor)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_vm_loading_spinner, { scale: 0.5 })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}