export class Account {
    public id?: number;
    public email!: string;
    public firstName!: string;
    public lastName!: string;
    public password!: string;
    public roleId = 0;
}

export class AccountSummary {
    public id?: number;
    public email!: string;
    public password!: string;
    public fullName!: string;
    public roleName!: string;
}
