
// vue
import { defineComponent, ref, onMounted } from 'vue';

// components
import EnvironmentCard from './environment-card.vue';
import ClereTitle from '@/components/clere-title.vue';

// services
import { useEnvironmentService } from '@/api/service/environment.service';

export default defineComponent({
    components: {
        EnvironmentCard,
        ClereTitle,
    },
    setup() {
        // services
        const { environments, getEnvironmentAll } = useEnvironmentService();

        // properties
        const isLoading = ref(true);

        // lifecycle hooks
        onMounted(async () => {
            await getEnvironmentAll();
            isLoading.value = false;
        });

        return {
            environments,
            isLoading,
        };
    },
});
